import Vue from 'vue'
import Vuex from 'vuex'
import request from './_api/request'
import socket from './modules/socket'
import customer from './modules/customer'
import account from './modules/account'
import feature from './modules/feature'
import room from './modules/room'
import booking from './modules/booking'

Vue.use(Vuex)

let toastTimeout = null
export default new Vuex.Store({
  state: {
    user: null,
    storeData: [],
    storeActive: 0,
    toast: {
      show: false,
      message: '',
      class: 'ld ld-slide-ttb-in'
    },
    map: {
      show: false,
      data: null,
      setPoint: {
        active: false,
        draggable: null,
        data: null
      }
    },
    payment: [
      // { id: 'cash', name: 'Cash' },
      // { id: 'card', name: 'EDC/Card' },
      // { id: 'qris', name: 'Qris' },
      { id: 'bank_transfer', name: 'Bank Transfer', customerRequired: true },
      { id: 'online', name: 'Xendit Payment', customerRequired: true }
    ],
    types: ['Doctors', 'Pharmacy', 'Medical Lab', 'Clinic', 'Hospital', 'Supply Store', 'Therapist'],
    educations: {
      main: [
        { name: 'Dr Umum', value: 'dr_umum' },
        { name: 'Drg Gigi', value: 'drg_gigi' },
        { name: 'Dr Spesialis', value: 'dr_spesialis' }
      ],
      sub: [
        { name: 'Spesialis Anak (Sp.A)', value: 'Spesialis Anak (Sp.A)' },
        { name: 'Spesialis Andrologi (Sp.And)', value: 'Spesialis Andrologi (Sp.And)' },
        { name: 'Spesialis Anestesiologi dan Terapi Intensif (Sp.An)', value: 'Spesialis Anestesiologi dan Terapi Intensif (Sp.An)' },
        { name: 'Spesialis Akupunktur Medik (Sp.Ak)', value: 'Spesialis Akupunktur Medik (Sp.Ak)' },
        { name: 'Spesialis Bedah (Sp.B)', value: 'Spesialis Bedah (Sp.B)' },
        { name: 'Spesialis Bedah Anak (Sp.BA)', value: 'Spesialis Bedah Anak (Sp.BA)' },
        { name: 'Spesialis Bedah Plastik, Rekonstruksi, dan Estetik (Sp.BP-RE)', value: 'Spesialis Bedah Plastik, Rekonstruksi, dan Estetik (Sp.BP-RE)' },
        { name: 'Spesialis Bedah Saraf (Sp.BS)', value: 'Spesialis Bedah Saraf (Sp.BS)' },
        { name: 'Spesialis Bedah Toraks, Kardiak, dan Vaskular (Sp.BTKV)', value: 'Spesialis Bedah Toraks, Kardiak, dan Vaskular (Sp.BTKV)' },
        { name: 'Spesialis Dermatologi dan Venereologi (Sp.DV)', value: 'Spesialis Dermatologi dan Venereologi (Sp.DV)' },
        { name: 'Spesialis Emergency Medicine (Kegawatdaruratan Medik) (Sp.EM)', value: 'Spesialis Emergency Medicine (Kegawatdaruratan Medik) (Sp.EM)' },
        { name: 'Spesialis Farmakologi Klinik (Sp.FK)', value: 'Spesialis Farmakologi Klinik (Sp.FK)' },
        { name: 'Spesialis Forensik dan Medikolegal (Sp.FM)', value: 'Spesialis Forensik dan Medikolegal (Sp.FM)' },
        { name: 'Spesialis Gizi Klinik (Sp.GK)', value: 'Spesialis Gizi Klinik (Sp.GK)' },
        { name: 'Spesialis Jantung dan Pembuluh Darah (Sp.JP)', value: 'Spesialis Jantung dan Pembuluh Darah (Sp.JP)' },
        { name: 'Spesialis Kedokteran Fisik dan Rehabilitasi (Sp.KFR)', value: 'Spesialis Kedokteran Fisik dan Rehabilitasi (Sp.KFR)' },
        { name: 'Spesialis Kedokteran Jiwa (Sp.KJ)', value: 'Spesialis Kedokteran Jiwa (Sp.KJ)' },
        { name: 'Spesialis Kedokteran Kelautan (Sp.KL)', value: 'Spesialis Kedokteran Kelautan (Sp.KL)' },
        { name: 'Spesialis Kedokteran Keluarga Layanan Primer (Sp.KKLP)', value: 'Spesialis Kedokteran Keluarga Layanan Primer (Sp.KKLP)' },
        { name: 'Spesialis Kedokteran Nuklir dan Teranostik Molekuler (Sp.KN)', value: 'Spesialis Kedokteran Nuklir dan Teranostik Molekuler (Sp.KN)' },
        { name: 'Spesialis Kedokteran Okupasi (Sp.Ok)', value: 'Spesialis Kedokteran Okupasi (Sp.Ok)' },
        { name: 'Spesialis Kedokteran Olahraga (Sp.KO)', value: 'Spesialis Kedokteran Olahraga (Sp.KO)' },
        { name: 'Spesialis Kedokteran Penerbangan (Sp.KP)', value: 'Spesialis Kedokteran Penerbangan (Sp.KP)' },
        { name: 'Spesialis Mikrobiologi Klinik (Sp.MK)', value: 'Spesialis Mikrobiologi Klinik (Sp.MK)' },
        { name: 'Spesialis Neurologi (Sp.N)', value: 'Spesialis Neurologi (Sp.N)' },
        { name: 'Spesialis Obstetri dan Ginekologi (Sp.OG)', value: 'Spesialis Obstetri dan Ginekologi (Sp.OG)' },
        { name: 'Spesialis Oftalmologi (Sp.M)', value: 'Spesialis Oftalmologi (Sp.M)' },
        { name: 'Spesialis Onkologi Radiasi (Sp.Onk.Rad)', value: 'Spesialis Onkologi Radiasi (Sp.Onk.Rad)' },
        { name: 'Spesialis Orthopaedi dan Traumatologi (Sp.OT)', value: 'Spesialis Orthopaedi dan Traumatologi (Sp.OT)' },
        { name: 'Spesialis Parasitologi Klinik (Sp.ParK)', value: 'Spesialis Parasitologi Klinik (Sp.ParK)' },
        { name: 'Spesialis Patologi Anatomi (Sp.PA)', value: 'Spesialis Patologi Anatomi (Sp.PA)' },
        { name: 'Spesialis Patologi Klinik (Sp.PK)', value: 'Spesialis Patologi Klinik (Sp.PK)' },
        { name: 'Spesialis Penyakit Dalam (Sp.PD)', value: 'Spesialis Penyakit Dalam (Sp.PD)' },
        { name: 'Spesialis Pulmonologi dan Kedokteran Respirasi (Sp.P)', value: 'Spesialis Pulmonologi dan Kedokteran Respirasi (Sp.P)' },
        { name: 'Spesialis Radiologi (Sp.Rad)', value: 'Spesialis Radiologi (Sp.Rad)' },
        { name: 'Spesialis Telinga Hidung Tenggorok Bedah Kepala Leher (Sp.THT-KL)', value: 'Spesialis Telinga Hidung Tenggorok Bedah Kepala Leher (Sp.THT-KL)' },
        { name: 'Spesialis Urologi (Sp.U)', value: 'Spesialis Urologi (Sp.U)' },
        { name: 'Spesialis Bedah Mulut dan Maksilofasial (Dokter Gigi) (Sp.BMMF)', value: 'Spesialis Bedah Mulut dan Maksilofasial (Dokter Gigi) (Sp.BMMF)' },
        { name: 'Spesialis Kedokteran Gigi Anak (Dokter Gigi) (Sp.KGA)', value: 'Spesialis Kedokteran Gigi Anak (Dokter Gigi) (Sp.KGA)' },
        { name: 'Spesialis Konservasi Gigi (Dokter Gigi) (Sp.KG)', value: 'Spesialis Konservasi Gigi (Dokter Gigi) (Sp.KG)' },
        { name: 'Spesialis Ortodonsia (Dokter Gigi) (Sp.Ort)', value: 'Spesialis Ortodonsia (Dokter Gigi) (Sp.Ort)' },
        { name: 'Spesialis Odontologi Forensik (Dokter Gigi) (Sp.OF)', value: 'Spesialis Odontologi Forensik (Dokter Gigi) (Sp.OF)' },
        { name: 'Spesialis Penyakit Mulut (Dokter Gigi) (Sp.PM)', value: 'Spesialis Penyakit Mulut (Dokter Gigi) (Sp.PM)' },
        { name: 'Spesialis Periodonsia (Dokter Gigi) (Sp.Perio)', value: 'Spesialis Periodonsia (Dokter Gigi) (Sp.Perio)' },
        { name: 'Spesialis Prostodonsia (Dokter Gigi) (Sp.Pros)', value: 'Spesialis Prostodonsia (Dokter Gigi) (Sp.Pros)' },
        { name: 'Spesialis Radiologi Kedokteran Gigi (Dokter Gigi) (Sp.RKG)', value: 'Spesialis Radiologi Kedokteran Gigi (Dokter Gigi) (Sp.RKG)' }
      ]
    }
  },
  mutations: {
    MAP (state, v) {
      state.map.show = v.show || false
      state.map.data = v.data || null
      state.map.setPoint.active = v.setPoint ? v.setPoint.active : null
      state.map.setPoint.data = v.setPoint ? v.setPoint.data : null
      state.map.setPoint.draggable = v.setPoint ? v.setPoint.draggable || null : null
    },
    USER (state, v) {
      state.user = v || null
    },
    STORE_DATA (state, v) {
      state.storeData = v || []
    },
    STORE_ACTIVE (state, v) {
      state.storeActive = parseInt(v) || 0
    },
    TOAST (state, v) {
      state.toast.class = 'ld ld-slide-ttb-in'
      state.toast.show = v.show
      state.toast.message = v.message
    }
  },
  actions: {
    async AUTH_LOGIN (context, params) {
      return await request.post('dashboard', params)
        .then(res => res)
        .catch(() => {
          return null
        })
    },
    async ME (context, query) {
      return request.get('user').then((res) => {
        if (res.status) {
          context.commit('USER', res.data.data)
          const role = res.data.data.role
          if (role === 'root' || role === 'admin') {
            context.commit('STORE_ACTIVE', 1)
          } else {
            context.commit('STORE_ACTIVE', parseInt(res.data.data.store))
            if (role === 'driver') {
              context.dispatch('trx/TRX_GET', '?store=' + parseInt(res.data.data.store) + '&driver=' + res.data.data.id + '&driverStatus=')
            }
          }
        } else {
          context.commit('USER', null)
        }
        return res
      }).catch((e) => {
        return null
      })
    },
    async WEBSITE_PROCESS (context, params) {
      return await request.put('/store/website', params).then(res => res).catch(err => err)
    },
    async WEBSITE_GET (context) {
      return await request.get('/store/website').then(res => res).catch(err => err)
    },
    async ME_UPDATE (context, params) {
      return await request.put('/user/me', params).then(res => res).catch(err => err)
    },
    async AREA_GET (context, q) {
      return await request.get('/shipping/area' + (q || '')).then(res => res).catch(err => err)
    },
    async STORE (context) {
      return request.get('store').then((res) => {
        if (res.status) {
          context.commit('STORE_DATA', res.data.data || [])
        } else {
          context.commit('STORE_DATA', [])
        }
        return res
      }).catch((e) => {
        return {
          status: false,
          data: e
        }
      })
    },
    async STORE_PROCESS (context, params) {
      return request.post('store', params).then((res) => {
        return res
      }).catch((e) => {
        return {
          status: false,
          data: e
        }
      })
    },
    async UPLOAD_FILE (context, params) {
      params.source = process.env.NODE_ENV === 'production' ? (params.source || 'others') : 'localhost'
      return await request.post('upload', params).then(res => res)
    },
    TOAST (context, params) {
      if (toastTimeout) {
        clearInterval(toastTimeout)
      }
      context.commit('TOAST', params)
      if (params.show) {
        toastTimeout = setInterval(() => {
          setTimeout(() => {
            params.show = false
            params.message = ''
            context.commit('TOAST', params)
          }, 300)
          clearInterval(toastTimeout)
        }, 4000)
      }
    }
  },
  modules: {
    socket,
    customer,
    account,
    feature,
    room,
    booking
  }
})
