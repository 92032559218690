<template>
  <v-container class="py-8">
    <v-row no-gutters class="justify-center">
      <v-col cols="12">
        <div class="d-flex flex-column align-center justify-center px-4 px-sm-8 pt-8 pt-sm-8 pb-8 white">
          <v-row v-if="parseInt($store.state.storeActive)" class="d-flex fill-width align-center justify-center pt-8 pb-4">
            <!-- <v-col v-if="$role([], $store.state.user.role)" cols="12" class="col-sm-4">
              <v-card @click.prevent="$router.push('/account-manager')" outlined class="pa-4 text-center secondary caption font-weight-bold" dark>
                <v-icon small class="mr-2">
                  mdi-account-cog
                </v-icon>
                Account Manager
              </v-card>
            </v-col> -->
            <v-col v-if="$role([], $store.state.user.role)" cols="12" class="col-sm-4">
              <v-card @click.prevent="$router.push('/store-manager')" outlined class="pa-4 text-center secondary caption font-weight-bold" dark>
                <v-icon small class="mr-2">
                  mdi-store-cog
                </v-icon>
                Vendor
              </v-card>
            </v-col>
            <v-col v-if="$role([], $store.state.user.role)" cols="12" class="col-sm-4">
              <v-card @click.prevent="OpenContentManager()" outlined class="pa-4 text-center secondary caption font-weight-bold" dark>
                <v-icon small class="mr-2">
                  mdi-web-sync
                </v-icon>
                Content Manager
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogWeb.show"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="rounded-0">
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialogWeb.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Content Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              class="font-weight-bold"
              @click="WebsiteProcess()"
            >
              Update
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="dialogWeb.data" class="py-6">
          <v-row no-gutters>
            <v-col v-if="false" cols="12" class="col-sm-4 pa-4">
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                <v-icon small class="mr-2" color="primary">
                  mdi-home
                </v-icon>
                HOME PAGE
              </div>
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogWeb.data.home_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Home Title"
              />
              <v-text-field
                v-model="dialogWeb.data.home_caption"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Home Caption"
              />
              <v-text-field
                v-model="dialogWeb.data.home_headline"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Home - Headline Title"
                :append-icon="dialogWeb.data.home_headline_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('home_headline_image')"
              />
              <v-textarea
                v-model="dialogWeb.data.home_headline_description"
                outlined
                rows="4"
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Home - Headline Description"
              />
              <v-text-field
                v-model="dialogWeb.data.home_event_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Home - Event Title"
                :append-icon="dialogWeb.data.home_event_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('home_event_image')"
              />
              <v-textarea
                v-model="dialogWeb.data.home_event_description"
                outlined
                rows="3"
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Home - Event Description"
              />
            </v-col>
            <v-col v-if="false" cols="12" class="col-sm-4 pa-4">
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                <v-icon small class="mr-2" color="primary">
                  mdi-newspaper-variant-outline
                </v-icon>
                NEWS PAGE
              </div>
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogWeb.data.page_news_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="News Title"
                :append-icon="dialogWeb.data.page_news_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('page_news_image')"
              />
              <v-text-field
                v-model="dialogWeb.data.page_news_caption"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="News Caption"
              />
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                <v-icon small class="mr-2" color="primary">
                  mdi-calendar-clock
                </v-icon>
                EVENT PAGE
              </div>
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogWeb.data.page_event_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Event Title"
                :append-icon="dialogWeb.data.page_event_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('page_event_image')"
              />
              <v-text-field
                v-model="dialogWeb.data.page_event_caption"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Event Caption"
              />
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                <v-icon small class="mr-2" color="primary">
                  mdi-image-multiple
                </v-icon>
                GALLERY PAGE
              </div>
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogWeb.data.page_gallery_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Gallery Title"
                :append-icon="dialogWeb.data.page_gallery_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('page_gallery_image')"
              />
              <v-text-field
                v-model="dialogWeb.data.page_gallery_caption"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Gallery Caption"
              />
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                <v-icon small class="mr-2" color="primary">
                  mdi-account
                </v-icon>
                ACCOUNT PAGE
              </div>
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogWeb.data.page_account_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Account Title"
                :append-icon="dialogWeb.data.page_account_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('page_account_image')"
              />
              <v-text-field
                v-model="dialogWeb.data.page_account_caption"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Account Caption"
              />
            </v-col>
            <v-col v-if="false" cols="12" class="col-sm-4 pa-4">
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                <v-icon small class="mr-2" color="primary">
                  mdi-phone
                </v-icon>
                CONTACT PAGE
              </div>
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogWeb.data.page_contact_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Contact Title"
                :append-icon="dialogWeb.data.page_contact_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('page_contact_image')"
              />
              <v-text-field
                v-model="dialogWeb.data.page_contact_caption"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Contact Caption"
              />
              <v-text-field
                v-model="dialogWeb.data.page_contact_address"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Contact Address"
              />
              <v-text-field
                v-model="dialogWeb.data.page_contact_email"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Contact Email"
              />
              <v-text-field
                v-model="dialogWeb.data.page_contact_whatsapp"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Contact Whatsapp (62)"
                prefix="62"
                @keypress="$NumberOnly"
              />
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                <v-icon small class="mr-2" color="primary">
                  mdi-information
                </v-icon>
                ABOUT PAGE
              </div>
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogWeb.data.page_about_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="About Title"
                :append-icon="dialogWeb.data.page_about_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('page_about_image')"
              />
              <v-text-field
                v-model="dialogWeb.data.page_about_caption"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="About Caption"
              />
            </v-col>
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12" class="col-sm-4 pa-4">
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                # CONTENT - ABOUT
              </div>
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogWeb.data.page_about_content_title"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="About - Content Title"
              />
              <v-textarea
                v-model="dialogWeb.data.page_about_content_description"
                outlined
                rows="6"
                dense
                hide-details
                class="rounded-0 mb-4"
                label="About - Content Description"
              />
              <!-- <v-text-field
                v-model="dialogWeb.data.page_about_content_visi"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="About - Visi Content Title"
                :append-icon="dialogWeb.data.page_about_content_visi_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('page_about_content_visi_image')"
              />
              <v-textarea
                v-model="dialogWeb.data.page_about_content_visi_description"
                outlined
                rows="3"
                dense
                hide-details
                class="rounded-0 mb-4"
                label="About - Visi Content Description"
              />
              <v-text-field
                v-model="dialogWeb.data.page_about_content_misi"
                outlined
                dense
                hide-details
                class="rounded-0 mb-4"
                label="About - Misi Content Title"
                :append-icon="dialogWeb.data.page_about_content_misi_image ? 'mdi-image-check' : 'mdi-image-plus'"
                @click:append="UploadImage('page_about_content_misi_image')"
              />
              <v-textarea
                v-model="dialogWeb.data.page_about_content_misi_description"
                outlined
                rows="3"
                dense
                hide-details
                class="rounded-0 mb-4"
                label="About - Misi Content Description"
              /> -->
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                # CONTENT - PRIVACY POLICY
              </div>
              <v-divider class="mb-4" />
              <v-textarea
                v-model="dialogWeb.data.page_pp"
                outlined
                rows="8"
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Content - Privacy Policy"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 pa-4">
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                # CONTENT - TERMS & CONDITIONS
              </div>
              <v-divider class="mb-4" />
              <v-textarea
                v-model="dialogWeb.data.page_terms"
                outlined
                rows="20"
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Content - Terms & Conditions"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 pa-4">
              <div class="body-2 pb-1 font-weight-bold d-flex align-center primary--text">
                # CONTENT - FREQUENTLY ASKED QUESTION
              </div>
              <v-divider class="mb-4" />
              <v-textarea
                v-model="dialogWeb.data.page_faq"
                outlined
                rows="20"
                dense
                hide-details
                class="rounded-0 mb-4"
                label="Content - Frequently Asked Question"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  data: () => ({
    loadingImageUpload: false,
    dialogWeb: {
      show: false,
      data: null
    }
  }),
  mounted () {
    this.dialogWeb.show = false
    this.dialogWeb.data = null
  },
  methods: {
    WebsiteProcess () {
      const data = this.dialogWeb.data
      data.page_contact_whatsapp = '62' + (data.page_contact_whatsapp || '')
      this.$store.dispatch('WEBSITE_PROCESS', data)
        .then((res) => {
          if (res.status) {
            this.dialogWeb.data = null
            this.dialogWeb.show = false
            this.$store.dispatch('TOAST', { show: true, message: 'Updated!' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
          }
        })
    },
    OpenContentManager () {
      this.$store.dispatch('WEBSITE_GET')
        .then((res) => {
          if (res.status) {
            delete res.data.data.galleries
            delete res.data.data.news
            delete res.data.data.banner
            this.dialogWeb.data = Object.assign({}, res.data.data)
            this.dialogWeb.show = true
          }
        })
    },
    UploadImage (key) {
      if (!this.loadingImageUpload) {
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e, key)
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event, key) {
      /* eslint-disable */
      const $ = this
      await $.$filetoBase64(event, async function (res) {
        if (res.status) {
          $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: 'website'
          }).then((resUpload) => {
            if (resUpload.status) {
              if (resUpload.data.data.res) {
                $.dialogWeb.data[key] = resUpload.data.data.secure_url
              } else {
                $.$store.dispatch('TOAST', { show: true, message: 'Gagal upload, silahkan coba lagi!' })
              }
            }
            $.loadingImageUpload = false
          })
        } else {
          res.data === 'file_size'
            ? $.$store.dispatch('TOAST', { show: true, message: 'Image size no more than 1Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>
