<template>
  <v-app>
    <Toast />
    <Auth v-if="!$store.state.user" />
    <v-main v-else class="grey lighten-4">
      <div class="main-appbar white">
        <v-row no-gutters>
          <!-- <v-col
            :class="[ColorStoreActive + ' darken-2']"
            cols="6"
            class="d-flex align-center justify-center font-weight-bold c-pointer pa-0 lh-0 col-sm-2"
          >
            <div v-if="$store.state.storeData.length">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="d-flex align-center justify-center fill-height px-4"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mr-2" dark x-small>
                      mdi-office-building-marker-outline
                    </v-icon>
                    <span class="d-inline-flex caption lh-0 white--text font-weight-black">
                      {{ $store.state.storeActive ? $store.state.storeData.filter(r => r.id === $store.state.storeActive)[0].name : 'Select Hotel' }}
                    </span>
                    <v-icon small class="ml-2" color="white">
                      mdi-chevron-down
                    </v-icon>
                  </div>
                </template>
                <v-list dense>
                  <v-list-item v-for="(s, iS) in (($store.state.user.role !== 'root' && $store.state.user.role !== 'admin') ? $store.state.storeData.filter(r => parseInt(r.id) === parseInt($store.state.user.store)) : $store.state.storeData)" :key="'store-' + iS" @click.prevent="$store.commit('STORE_ACTIVE', s.id)">
                    <v-list-item-title>
                      <v-icon v-if="parseInt(s.id) === $store.state.storeActive" small class="mr-2" color="primary">
                        mdi-check-circle
                      </v-icon>
                      <v-icon v-else small class="mr-2">
                        mdi-office-building-marker-outline
                      </v-icon>
                      {{ s.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div v-else>
              No Branch
            </div>
          </v-col> -->
          <v-col cols="12">
            <v-tabs
              fixed-tabs
              :height="48"
              :background-color="ColorStoreActive"
              dark
            >
              <v-tabs-slider :color="ColorStoreActive + ' darken-2'" />
              <v-tab to="/" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-home
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Home
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager'], $store.state.user.role)" to="/member" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-account-group
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Customer
                </span>
              </v-tab>
              <v-tab v-if="$role([], $store.state.user.role)" to="/store-manager" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-map-marker-star
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Vendor
                </span>
              </v-tab>
              <v-tab v-if="$role([], $store.state.user.role)" to="/features/main-banner" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-newspaper-variant-outline
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Event & Media
                </span>
              </v-tab>
              <v-tab @click.prevent="DO_LOGOUT" class="text-capitalize ls-05 red--text white">
                <v-icon small color="red">
                  mdi-power
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Sign Out
                </span>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </div>
      <div class="main-container">
        <v-container fluid>
          <router-view/>
        </v-container>
      </div>
    </v-main>
    <Map />
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  computed: {
    ColorStoreActive () {
      // const storeActive = parseInt(this.$store.state.storeActive)
      const color = 'primary'
      // if (storeActive) {
      //   const store = this.$store.state.storeData.find(r => parseInt(r.id) === storeActive)
      //   if (store) {
      //     if (store.color) {
      //       return store.color
      //     }
      //   }
      //   const index = this.$store.state.storeData.findIndex(r => parseInt(r.id) === storeActive)
      //   switch (index) {
      //     case 0:
      //       color = 'primary'
      //       break
      //     case 1:
      //       color = 'secondary'
      //       break
      //     case 2:
      //       color = 'blue'
      //       break
      //     case 3:
      //       color = 'green'
      //       break
      //     case 4:
      //       color = 'teal'
      //       break
      //     case 5:
      //       color = 'lime darken-1'
      //       break
      //     case 6:
      //       color = 'orange'
      //       break
      //     case 7:
      //       color = 'indigo'
      //       break
      //     default:
      //       break
      //   }
      // }
      return color
    }
  },
  methods: {
    DO_LOGOUT () {
      if (!confirm('Logout ?')) {
        return false
      }
      localStorage.removeItem('t')
      this.$store.commit('USER', null)
    }
  }
}
</script>

<style lang="scss">
html, a, a:link, a:visited, a:hover, a:active {
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}
html {
  // height: 100vh!important;
  // overflow: hidden!important;
  >body {
    // overflow: hidden!important;
    >.v-application {
      // height: 100vh!important;
      // overflow: auto!important;
      .display-4,
      .display-3,
      .display-2,
      .display-1,
      .headline,
      .title,
      .subtitle-1,
      .subtitle-2,
      .body-1,
      .body-2,
      .caption,
      .overline {
        font-family: 'Poppins', sans-serif!important;
      }
    }
  }
}

.main-appbar {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 2;
  height: 48px!important;
}
.main-container {
  padding-top: 48px;
  position: relative;
  // overflow-y: auto;
}
.c-pointer {
  cursor: pointer!important;
}
.fill-width {
  width: 100%!important;
}
.p-relative {
  position: relative!important;
}
.ls-0 {
  letter-spacing: 0px!important;
}
.ls-05 {
  letter-spacing: .5px!important;
}
.ls-1 {
  letter-spacing: 1px!important;
}
.lh-0 {
  line-height: 100%!important;
}
a {
  text-decoration: none!important;
  color: unset!important;
}
.v-card {
  border-radius: 0px!important;
}
.file-uploader {
  width: 0px;
  height: 0px;
  position: fixed;
  bottom: -100%;
  z-index: -2;
}
.v-tabs {
  .v-slide-group__prev,
  .v-slide-next {
    display: none!important;
  }
}
.theme--dark {
  &.btn-checkout {
    &.v-btn--disabled {
      &.v-btn--has-bg {
        &.v-btn {
          background-color: rgba(0, 0, 0, .1)!important;
          color: rgba(0, 0, 0, .3)!important;
        }
      }
    }
  }
}
.caption {
  letter-spacing: 0px!important;
}
.caption-small {
  font-size: 12px!important;
  letter-spacing: 0px;
}
.v-textarea {
  textarea {
    line-height: 125%!important;
  }
}
a {
  &.disabled {
    pointer-events: none!important;
  }
}

.v-calendar-monthly {
  border-radius: 4px!important;
  // overflow: hidden;
  &.v-calendar-weekly {
    @media only screen and (max-width: 768px) {
      border-radius: 0px!important;
      width: 117%;
      margin-left: -8.5%!important;
      margin-right: -8.5%!important;
      border: solid 0px!important;
    }
  }
  .v-calendar-weekly__head {
    .v-calendar-weekly__head-weekday {
      background-color: #412a54!important;
      padding: 4px 4px 4px 4px;
      font-weight: bold;
      color: #ffffff!important;
      &.v-outside {
        color: #9e959f!important;
      }
      &.v-present {
        color: #fcb068!important;
      }
    }
  }
  .v-calendar-weekly__day {
    min-height: 45px;
    padding: 0px 5px 10px 5px;
    .v-event {
      margin-top: 5px!important;
      width: 100%!important;
      height: 12px!important;
      display: flex!important;
      justify-content: flex-start!important;
      align-items: center!important;
      line-height: unset!important;
      // left: 55%;
      left: 0px;
      border-radius: 20px!important;
      >.booking-counter {
        position: relative;
        padding: 0px!important;
        font-size: .5rem!important;
        width: 100%;
        height: 100%;
        text-align: center;
        display:flex;
        justify-content: center!important;
        align-items: center!important;
        span {
          &.booking-counter-progress {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            background-color: #256d85;
            z-index: -1;
          }
        }
      }
    }
    :nth-child(2) {
      &.v-event {
        // margin-top: -10px!important;
        margin-top: 5px!important;
        margin-bottom: 5px!important;
        &.grey {
          background: transparent!important;
        }
        >.booking-counter {
          span {
            color: #64007d;
            font-size: .7rem;
            font-weight: bold;
            &.booking-counter-progress {
              display: none;
            }
          }
        }
      }
    }
    &.v-present {
      background: #fbede6;
    }
    >.v-calendar-weekly__day-label {
      margin-top: 10px;
      margin-bottom: 0px;
      .v-btn--fab {
        &.v-size--small {
          width: 25px!important;
          height: 25px!important;
        }
        &.primary {
          background-color: #f4700b!important;
          color: #fff!important;
        }
        &.transparent {
          background-color: #e3d9e9!important;
          color: #412a54!important;
        }
      }
    }
    .v-event-more {
      display: none;
    }
  }
}
table {
  thead {
    &.v-data-table-header {
      >tr {
        background-color: #FF0055!important;
        >th {
          // height: 80!important;
          border-bottom: 0px!important;
          >i.theme--light,
          >span {
            color: #fff!important;
          }
          >i.theme--light {
            font-size: .8rem!important;
            margin-left: 5px!important;
            margin-top: -1px!important;
          }
        }
      }
    }
  }
}
.caption-small {
  font-size: .7rem!important;
}
</style>
