import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  customVariables: ['@/scss/variables.scss'],
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#FF0055',
        secondary: '#ED787B',
        accent: '#e0e0e0',
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3
      }
    }
  },
  treeShake: true,
  defaultAssets: {
    font: {
      family: 'Poppins'
    }
  }
})
